import { PageSection } from "interfaces/api_interfaces";
import { useLocalization } from "providers/LocalizationProvider";
import BottomTextTemplate from "./templates/BottomTextTemplate";
import HeroTemplate from "./templates/HeroTemplate";
import SideTextTemplate, {
  SideTextTemplateAlign,
} from "./templates/SideTextTemplate";

interface Props {
  template: PageSection;
  showFooter?: boolean;
}

const PageContent = ({ template, showFooter = false }: Props): JSX.Element => {
  const { toLocalizedString } = useLocalization();
  const {
    id,
    name,
    price,
    alt,
    image,
    actions,
    description,
    text_align,
    vimeo_id,
  } = template;

  return (
    <>
      {(() => {
        switch (text_align) {
          case "RIGHT":
            return (
              <SideTextTemplate
                key={id}
                template={template}
                textAlign={SideTextTemplateAlign.Right}
                showFooter={showFooter}
              />
            );
          case "LEFT":
            return (
              <SideTextTemplate
                key={id}
                template={template}
                textAlign={SideTextTemplateAlign.Left}
                showFooter={showFooter}
              />
            );
          case "BOTTOM":
            return (
              <BottomTextTemplate
                key={id}
                template={template}
                showFooter={showFooter}
              />
            );
          default:
            return (
              <HeroTemplate
                key={id}
                alt={toLocalizedString(alt)}
                price={price}
                name={toLocalizedString(name)}
                description={toLocalizedString(description)}
                actions={actions}
                image={image}
                showFooter={showFooter}
                vimeo_id={vimeo_id}
              />
            );
        }
      })()}
    </>
  );
};

export default PageContent;
